import React, { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDepartments, setShowDepartments] = useState(false);

  const departments = [
    { name: "Computer Science Engineering in Data Science", studentUrl: "https://student.campushub360.xyz/", facultyUrl: "https://faculty.campushub360.xyz/", hodUrl: "https://admin.campushub360.xyz/", adminUrl: "https://ece-admin.campushub360.xyz/" },
    { name: "Electronics & Communication", studentUrl: "https://ece-student.campushub360.xyz/", facultyUrl: "https://ece-faculty.campushub360.xyz/", hodUrl: "https://ece-hod.campushub360.xyz/", adminUrl: "https://ece-admin.campushub360.xyz/" },
  ];

  const toggleDepartments = () => {
    setShowDepartments(!showDepartments);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white sticky top-0 z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo Section */}
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl font-bold tracking-wider">CampusHub360</h1>
        </div>

        {/* Navigation Links */}
        <nav className="hidden md:flex space-x-6">
          <a href="#features" className="hover:text-orange-500 transition">
            Features
          </a>
          <a href="#about" className="hover:text-orange-500 transition">
            About Us
          </a>
          <a href="#contact" className="hover:text-orange-500 transition">
            Contact
          </a>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Action Buttons */}
        <div className="hidden md:flex space-x-4">
          <button
            onClick={toggleDepartments}
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Student
          </button>
          <button
            onClick={toggleDepartments}
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Faculty
          </button>
          <button
            onClick={toggleDepartments}
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login as Admin
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-gray-800 text-white p-4 flex flex-col space-y-2">
          <a href="#features" className="hover:text-orange-500 transition">Features</a>
          <a href="#about" className="hover:text-orange-500 transition">About Us</a>
          <a href="#contact" className="hover:text-orange-500 transition">Contact</a>
          <button
            onClick={toggleDepartments}
            className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Login Options
          </button>
        </div>
      )}

      {/* Departments List Dropdown */}
      {showDepartments && (
        <div className="bg-gray-800 text-white p-4 md:w-3/4 mx-auto rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4 text-orange-500">Login to Your Department</h2>
          <ul className="space-y-4">
            {departments.map((dept, index) => (
              <li key={index} className="border-b border-gray-700 py-2">
                <h3 className="text-lg font-semibold">{dept.name}</h3>
                <div className="flex flex-wrap justify-start gap-2 mt-2">
                  <a
                    href={dept.studentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
                  >
                    Login as Student
                  </a>
                  <a
                    href={dept.facultyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 px-4 py-2 rounded hover:bg-blue-600 transition"
                  >
                    Login as Faculty
                  </a>
                  <a
                    href={dept.hodUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-500 px-4 py-2 rounded hover:bg-green-600 transition"
                  >
                    Login as HOD
                  </a>
                  <a
                    href={dept.adminUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-red-500 px-4 py-2 rounded hover:bg-red-600 transition"
                  >
                    Login as Admin
                  </a>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => setShowDepartments(false)}
            className="mt-4 bg-orange-500 px-4 py-2 rounded hover:bg-orange-600 transition"
          >
            Close
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
